<script>
import InfoBoxForm from "@/views/info-box/infoBoxForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    infoBoxService: "infoBoxService"
  },
})
export default class InfoBox_Detail extends mixins(InfoBoxForm) {
  formId = "detail-infoBox-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.infoBox_detail;
  }

  async afterLoad() {
    this.model = await this.infoBoxService.read(this.$route.params.id);

    this.isReady = true;
  }
}
</script>
